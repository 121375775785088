import _ from 'lodash';
import { useState } from 'react';

import { AddressFormCheckout } from '@/components/checkout/AddressFormCheckout/AddressFormCheckout';
import { ShippingAddress } from '@/components/checkout/ShippingAddress/ShippingAddress';
import Button from '@/components/common/Button/Button';
import type { Address } from '@/models/customer/address';
import type { Customer } from '@/models/customer/customer';

interface AddressContainerProps {
  addresses: Array<Address>;
  customer: Customer;
  onChangeAddress: (address: Address) => Promise<void>;
  onSaveAddress: (address: Address, isNew: boolean) => Promise<void>;
}

const AddressContainer: React.FC<AddressContainerProps> = ({
  customer,
  addresses,
  onChangeAddress,
  onSaveAddress,
}) => {
  const [addressId, setAddressId] = useState<string>('');
  const [editing, setEditing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editAddress, setEditAddress] = useState<Address | undefined>(
    undefined,
  );
  const handleEditAddress = (address?: Address) => {
    setEditAddress(address);
    setEditing(true);
  };

  const handleCloseEditAddress = () => {
    setEditAddress(undefined);
    setEditing(false);
  };

  const handleSaveAddress = async (address: Address) => {
    try {
      setLoading(true);
      await onSaveAddress(address, !editAddress);
    } finally {
      setLoading(false);
    }
  };

  return editing ? (
    <div className="pt-6">
      <AddressFormCheckout
        customer={customer}
        address={editAddress}
        closeEditAddress={handleCloseEditAddress}
        saveAddress={(address) => handleSaveAddress(address)}
        type={editAddress ? 'edit' : 'add'}
        disabled={loading}
      />
    </div>
  ) : (
    <div className="flex flex-col">
      <div className="px-6 py-3">
        <p className="font-interSemibold text-3xl">Change Address</p>
      </div>
      <hr />
      <div className="my-2 px-2 md:px-4">
        {addresses &&
          addresses.map((address: Address) => (
            <div key={address.addressId} className="p-3">
              <ShippingAddress
                address={address}
                buttonLabel={'Edit'}
                onSelect={() => setAddressId(address.addressId)}
                onClick={() => {
                  handleEditAddress(address);
                }}
                selected={addressId === address.addressId}
                showRadio
              />
            </div>
          ))}
      </div>
      <div className="sticky bottom-0 bg-white">
        <div className="flex flex-col items-center p-6">
          <Button
            theme="tertiary"
            className="w-full rounded-lg bg-off-white py-4"
            onClick={() => handleEditAddress()}
          >
            + Add a new address
          </Button>
        </div>
        <hr />
        <div className="flex items-center justify-center py-6">
          <Button
            theme="primary"
            className=" rounded-full px-6 py-2.5"
            disabled={!addressId}
            onClick={() => {
              const address = _.find(addresses, { addressId });
              onChangeAddress(address as Address);
            }}
          >
            Save and continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddressContainer;
