import _ from 'lodash';
import { observer } from 'mobx-react-lite';

import { ProductRestrictedType } from '@/models/api';
import { useStore } from '@/models/root-store';

import DeliveryRestrictedAddressCheck from './DeliveryRestrictedAddressCheck';
import DeliveryRestrictedSuburbCheck from './DeliveryRestrictedSuburbCheck';

interface DeliveryRestrictedCheckProps {}

// the component takes postcode input, return the delivery list
// the customer selects the delivery address to proceed product restriction check
// if the product is restricted, show the message and allow the customer enter another postcode
// if the product is not restricted, show the message and close the modal
const DeliveryRestrictedCheck: React.FC<DeliveryRestrictedCheckProps> =
  observer(() => {
    const {
      generalStore: {
        restrictedProductsCheck: productSkus,
        setRestrictedProductsCheck,
      },
      productStore: { productsLookup, deliveryAddress },
      customerStore: { customer },
    } = useStore();
    const open = productSkus.length > 0;

    const sku = _.first(productSkus) as string;
    const product = sku ? productsLookup[sku] : undefined;

    return (
      <>
        {customer.defaultAddress ? (
          <DeliveryRestrictedAddressCheck
            open={open}
            name={product?.name || ''}
            deliveryAddress={deliveryAddress}
            onClose={() => setRestrictedProductsCheck([])}
          />
        ) : (
          <DeliveryRestrictedSuburbCheck
            open={open}
            onClose={() => setRestrictedProductsCheck([])}
            type={ProductRestrictedType.PRODUCT}
            productSkus={productSkus}
            name={product?.name || ''}
          />
        )}
      </>
    );
  });

export default DeliveryRestrictedCheck;
