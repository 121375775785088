import { RadioButton } from '@/components/common/RadioButton/RadioButton';
import type { Address } from '@/models/customer/address';
import { AddressMethod } from '@/shared/constant';

export interface IShippingAddress {
  address: Address;
  buttonLabel: string;
  onClick: (event: any) => void;
  onSelect?: () => void;
  selected?: boolean;
  customStyle?: string;
  isCheckOut?: boolean;
  showRadio?: boolean;
}

export const ShippingAddress: React.FC<IShippingAddress> = ({
  address,
  buttonLabel,
  onSelect,
  onClick,
  customStyle,
  isCheckOut = true,
  selected = false,
  showRadio = false,
}) => {
  const defaultMargin = customStyle || 'my-3';
  return (
    <div className={`${defaultMargin} flex justify-between rounded-lg`}>
      <div className="relative flex w-full items-center space-x-2">
        {showRadio && (
          <div onClick={onSelect}>
            <RadioButton isActive={selected} />
          </div>
        )}
        <div className="w-full">
          <div className="flex justify-between font-interMedium text-lg">
            <div>
              {address.preferred && (
                <span className="rounded-full bg-grey-liter px-2 py-1 text-center text-xs text-black">
                  Default
                </span>
              )}
            </div>
          </div>
          <div className="flex w-full justify-between">
            <div className="text-medium font-interSemibold leading-8">
              <span>
                {address.firstName} {address.lastName} (
                {AddressMethod[address.method]})
              </span>
            </div>
            <div
              className="cursor-pointer font-interSemibold text-sm text-attention"
              onClick={onClick}
            >
              {buttonLabel}
            </div>
          </div>
          <div className="font-interMedium text-xs">
            {address.street}, {address.suburb}, {address.state}{' '}
            {address.postcode} <br />
            {address.phone}
          </div>
          <div className={!isCheckOut ? 'h-[22px]' : 'h-auto'}>
            {address.instructions && (
              <div className="line-clamp-1 font-interMedium text-xs">
                <span className="font-interMedium">Instructions: </span>
                {address.instructions}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
