// @ts-nocheck
import dayjs from 'dayjs';
import dayjsTimeZone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import _, { find } from 'lodash';
import randomise from 'randomatic';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import UAParser from 'ua-parser-js';
import validator from 'validator';

import type { ScrollDirection } from '@/hooks';
import type {
  FilterRecordModelType,
  FilterSectionRecordModelType,
  MenuHierarchyRecordModelType,
  PlanCategory,
  ProductModelType,
} from '@/models/api';
import type { LineItem, OrderPlan } from '@/models/cart/order-plan';
import { loadString, remove, saveString } from '@/utils/local-storage';

import type { ToastEnum } from './constants';
import {
  DEFAULT_TIMEZONE,
  Mode,
  MultiOrderStatus,
  SearchModes,
  SearchState,
} from './constants';

dayjs.extend(utc);
dayjs.extend(dayjsTimeZone);
const PROFILE_STAGE_SKIP_UNTIL = 'profileStageSkipUntil';

export const randomId = (length: number = 16): string => {
  return randomise('Aa0', length);
};

export function SetCategoryOnScroll(
  categoryRefs: any,
  setCategory: any,
  NAV_HEIGHT: any = 95,
) {
  const [scrollPosition, setScrollPosition] = useState(0);

  const visibleMenuSection = () => {
    const offsets = categoryRefs.current.map(
      (section: { offsetTop: number }) =>
        section && section.offsetTop - NAV_HEIGHT,
    );
    const offsetsInRange = offsets.filter(
      (offset: number) => offset && offset <= scrollPosition,
    );
    const offset = offsetsInRange[offsetsInRange.length - 1];
    categoryRefs.current.forEach((category: { offsetTop: number; id: any }) => {
      if (category) {
        if (!category.offsetTop) return;
        if (offset === category.offsetTop - NAV_HEIGHT) {
          setCategory(category.id);
        }
      }
    });
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    visibleMenuSection();
  }, [scrollPosition]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
}

// Use lodash round instead
export const roundTotal = (
  number: string | number,
  precision: number = 2,
): number => {
  const floatNumber = typeof number === 'string' ? parseFloat(number) : number;
  return _.round(floatNumber, precision);
};

export const getDiscountedValue = (
  value: string | number,
  discount: number,
  rounding: boolean = false,
): number => {
  const floatValue = typeof value === 'string' ? parseFloat(value) : value;
  if (rounding) {
    return roundTotal((floatValue * (100 - discount)) / 100);
  }
  return (floatValue * (100 - discount)) / 100;
};

export const formatLineItems = (lineItems: any): LineItem[] => {
  return lineItems.map((item: any) => {
    const product = item.sku;
    const { qty } = item;
    const { attributes } = item;
    return {
      sku: product,
      qty,
      attributes,
    };
  });
};

export const findLineItemByPositionAndDay = (
  position: number,
  day: number,
  items: LineItem[],
): LineItem | undefined => {
  return items.find((item) => {
    const { attributes } = item;
    return (
      find(attributes, { name: 'position', value: position }) &&
      find(attributes, { name: 'day', value: day })
    );
  });
};

export const formatPlans = (plans: any[]): OrderPlan[] => {
  return plans.map((plan: any) => {
    return {
      planId: randomise('Aa0', 16),
      category: plan.category as PlanCategory,
      subCategory: plan.subCategory as string, // This needs to be updated
      name: plan.name || '',
      image: plan.cms?.image?.url,
      netPrice: plan.price || 0,
      attributes: plan.attributes || [],
      lineItems: formatLineItems(plan.lineItems),
    };
  });
};

export const getEnumValue = (enumConstant: any, value: string) => {
  const keys = Object.keys(enumConstant).filter(
    (x) => enumConstant[x] === value,
  );
  return keys.length > 0 ? keys[0] : '';
};

export const sanitize = (data: object) => {
  return JSON.parse(
    JSON.stringify(data, (key, value) => {
      return value === null ? undefined : value;
    }),
  );
};

// Adds a hyphen prefix to children
export const formatPanelInfo = (panelInfo: any) => {
  const newInfo = [];
  panelInfo.forEach((info) => {
    newInfo.push(info);
    info.children?.forEach((child) => {
      newInfo.push({
        ...child,
        name: `-${child.name}`,
      });
    });
  });
  return newInfo;
};

interface ConvertToTimezoneArgs {
  dateTime: string;
  timezone: string;
}

export const convertToTimezone = ({
  dateTime,
  timezone,
}: ConvertToTimezoneArgs): dayjs.Dayjs => {
  const utcDateTime = dayjs(dateTime).utc();
  const timezoneDateTime = utcDateTime.tz(timezone);

  return timezoneDateTime;
};

export const isValidEmailUpdate = (newEmail: string, oldEmail: string) => {
  if (newEmail === oldEmail) {
    return 'Please enter a different email.';
  }
  return (
    !newEmail || validator.isEmail(newEmail) || 'Please enter a valid email.'
  );
};

export const isValidEmail = (email: string): string | boolean => {
  return !email || validator.isEmail(email) || 'Please enter a valid email.';
};

export const isValidString = (str: string): string | boolean => {
  return (
    !str ||
    !validator.isEmpty(str, { ignore_whitespace: true }) ||
    'This field is required'
  );
};

export const scrollToElementById = (
  id,
  options?: ScrollIntoViewOptions,
  alignToTop?: boolean,
) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView(alignToTop, options);
  }
};

export const getFirstAvailableId = (refs) => {
  if (!refs || !refs.current) {
    return null;
  }

  const firstAvailableRef = refs.current.find((ref) => !!ref);
  return firstAvailableRef ? firstAvailableRef.id : null;
};

export const isNewFeatureEligible = (email: string) => {
  const testDomains = process.env.NEXT_PUBLIC_TEST_DOMAINS || '';
  const domain = (email && email.split('@').pop()) || '';
  return testDomains?.split(',').includes(domain) || testDomains === 'all';
};

export const checkVisiblePathnames = (
  pathname: string,
  visiblePathnames: string,
  invisiblePathnames: string,
): boolean => {
  const visiblePathnamesArray = visiblePathnames
    .split(',')
    .map((path) => path.trim());
  const invisiblePathnamesArray = invisiblePathnames
    .split(',')
    .map((path) => path.trim());

  // Check if the pathname matches any visible path
  const isVisible = _.some(visiblePathnamesArray, (path) => {
    if (path.includes('*')) {
      const pathPrefix = path.split('*')[0];
      return pathname.startsWith(pathPrefix);
    }
    return path === pathname;
  });

  if (isVisible) {
    return true;
  }

  // Check if the pathname matches any invisible path
  const isInvisible = _.some(invisiblePathnamesArray, (path) => {
    if (path.includes('*')) {
      const pathPrefix = path.split('*')[0];
      return pathname.startsWith(pathPrefix);
    }
    return path === pathname;
  });

  return !isInvisible;
};

export const setProfileStageSkipUntil = (days: number = 7) => {
  const profileStageSkipUntil = dayjs().add(days, 'days').toISOString();
  saveString(PROFILE_STAGE_SKIP_UNTIL, profileStageSkipUntil);
};

export const removeProfileStageSkipUntil = () => {
  remove(PROFILE_STAGE_SKIP_UNTIL);
};

export const checkProfileStageSkipUntil = () => {
  const profileStageSkipUntil = loadString(PROFILE_STAGE_SKIP_UNTIL);
  if (!profileStageSkipUntil) {
    return true;
  }
  const skipUntil = dayjs(profileStageSkipUntil);
  return !dayjs().isBefore(skipUntil);
};

export const getSMOV = (thresholdDescription: Array<any>): number => {
  return _.minBy(thresholdDescription, (item) => item?.threshold)?.threshold;
};

export const getFreeShippingThreshold = (
  thresholdDescription: Array<any>,
): number => {
  return _.maxBy(thresholdDescription, (item) => item?.threshold)?.threshold;
};

export const showToast = (message: string, type: ToastEnum, id: string) => {
  const isToastActive = toast.isActive(id);

  switch (type) {
    case 'info':
      return !isToastActive && toast.info(message, { toastId: id });
    case 'success':
      return !isToastActive && toast.success(message, { toastId: id });
    case 'warning':
      return !isToastActive && toast.warning(message, { toastId: id });
    case 'error':
      return !isToastActive && toast.error(message, { toastId: id });
    case 'default':
      return !isToastActive && toast(message, { toastId: id });
    default:
      // Handle unsupported toast types or fallback to the default toast type.
      return !isToastActive && toast(message, { toastId: id });
  }
};

export function scrollToJustAbove(element) {
  const offSet = element.offsetTop;
  window.scrollTo(window.scrollX, offSet);
}

export function getClientDetails() {
  const parser = new UAParser();
  const uaResult = parser.getResult();
  return {
    browser: uaResult.browser.name,
    deviceType: uaResult.device.type === 'mobile' ? 'Mobile Web' : 'Web',
  };
}

export function generateShownFilters(
  filterCmsData: FilterRecordModelType,
  key: string,
) {
  const clonedFilter = _.clone(filterCmsData);
  const sections: FilterSectionRecordModelType[] | [] =
    filterCmsData?.sections || [];
  const updatedSections = sections.filter(
    (filterSection: FilterSectionRecordModelType) => {
      const hasCategory = filterSection.category.some(
        (item: any) => item.key === key,
      );
      const categoryEmpty = _.isEmpty(filterSection.category);
      return hasCategory || categoryEmpty;
    },
  );
  clonedFilter.sections = updatedSections;
  return clonedFilter;
}

export const generateShownSortOptions = (sortMenuCms, key) => {
  return sortMenuCms.filter(
    (sortOption) =>
      sortOption.pagePaths?.length === 0 ||
      sortOption.pagePaths?.some((path) => path?.path === key),
  );
};
export const filterActiveMenuHierarchies = (
  menuHierarchies: MenuHierarchyRecordModelType[],
) => {
  _.remove(menuHierarchies, { active: false });
  _.forEach(menuHierarchies, (menuHierarchy) => {
    if (!_.isEmpty(menuHierarchy.childrenCategories)) {
      filterActiveMenuHierarchies(menuHierarchy.childrenCategories);
    }
  });
  return menuHierarchies;
};

export const populateHierarchySlug = (
  menuHierarchies: MenuHierarchyRecordModelType[],
  parentSlug?: string | null,
) => {
  menuHierarchies.forEach((menuHierarchy) => {
    menuHierarchy.hierarchySlug = parentSlug
      ? `${parentSlug}/${menuHierarchy.slug}`
      : menuHierarchy.slug;
    if (
      menuHierarchy.childrenCategories &&
      menuHierarchy.childrenCategories.length > 0
    ) {
      populateHierarchySlug(
        menuHierarchy.childrenCategories,
        menuHierarchy.hierarchySlug,
      );
    }
  });
};

export const getStatusAttributes = (status: MultiOrderStatus) => {
  switch (status) {
    case MultiOrderStatus.REDEEMED:
      return {
        color: 'text-success-copy',
        icon: 'check_circle',
        text: MultiOrderStatus.REDEEMED,
        bg: 'bg-white',
      };
    case MultiOrderStatus.Locked:
      return {
        color: 'text-grey-disable',
        icon: 'lock_clock',
        text: MultiOrderStatus.UNLOCKED,
        bg: 'bg-white',
      };
    default:
      return {
        color: 'text-primary',
        icon: 'lock_open',
        text: MultiOrderStatus.UNLOCKED,
        bg: 'bg-white',
      };
  }
};
export const getMultiOrderStatusPromotion = (
  orderIndex,
  redeemedOrders,
  discounts,
) => {
  const orderNumber = orderIndex + 1;
  if (orderNumber === 1) {
    return {
      status: MultiOrderStatus.REDEEMED,
      discount: discounts[0],
    };
  }
  if (orderNumber <= redeemedOrders) {
    return {
      status: MultiOrderStatus.REDEEMED,
      discount: discounts[1],
    };
  }
  if (orderNumber === redeemedOrders + 1) {
    return {
      status: MultiOrderStatus.UNLOCKED,
      discount: discounts[1],
    };
  }
  return {
    status: MultiOrderStatus.Locked,
    discount: discounts[1],
  };
};

export const getStylingFromMarks = (
  marks: string[] | undefined,
  textHighlightColor,
) => {
  const classNames: string[] = []; // Array to store class names
  let inlineStyle: Record<string, any> = {
    color: 'black',
  };
  if (marks) {
    marks.forEach((mark: string) => {
      classNames.push(mark);
      if (mark === 'strong') {
        inlineStyle = {
          ...inlineStyle,
          color: textHighlightColor,
          fontWeight: 'bold',
        };
      }
    });
  }
  return { classNames, inlineStyle };
};

export const sleep = (ms: number) =>
  new Promise((r) => {
    setTimeout(r, ms);
  });

export const searchBarStyles = (state: SearchState, mode: Mode) => {
  switch (state) {
    case SearchState.ACTIVE:
      return {
        baseStyles:
          'rounded-md bg-white border border-solid border-grey-darker',
      };
    case SearchState.COMPLETE:
      return {
        baseStyles:
          'rounded-md border border-solid border-neutral-200 bg-off-grey',
      };
    case SearchState.INACTIVE:
    case SearchState.PENDING:
      return {
        baseStyles:
          'rounded-md border border-solid border-neutral-200 bg-off-grey',
      };
    default:
      return {
        baseStyles: 'bg-white',
        notExpanded: ` pr-2 md:pr-5 ${mode !== Mode.EDIT_SUBSCRIPTION ? ' border-r border-zinc-500' : ''}`,
      };
  }
};
const getPathname = (url) => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = `https://${url}`;
  }
  try {
    return new URL(url).pathname;
  } catch (e) {
    return '';
  }
};
export const matchUrlPathname = (url1, url2) => {
  const path1 = getPathname(url1);
  const path2 = getPathname(url2);
  return path1.includes(path2);
};

export const getSearchMode = (pathname: string): SearchModes => {
  if (pathname.includes('/menu/search?mode=edit-subscription')) {
    return SearchModes.EDIT_SUBSCRIPTION_SEARCH;
  }
  if (pathname.includes('/swap/search?mode=edit-cart')) {
    return SearchModes.SWAP_EDIT_CART_SEARCH;
  }
  if (pathname.includes('/swap?mode=edit-cart')) {
    return SearchModes.SWAP_EDIT_CART;
  }
  if (pathname.includes('/swap/search?mode=edit-subscription')) {
    return SearchModes.SWAP_EDIT_SUBSCRIPTION_SEARCH;
  }
  if (pathname.includes('/swap?mode=edit-subscription')) {
    return SearchModes.SWAP_EDIT_SUBSCRIPTION;
  }
  if (pathname.includes('/swap/search')) {
    return SearchModes.SWAP_SEARCH;
  }
  if (pathname.includes('/swap')) {
    return SearchModes.Swap;
  }
  if (pathname.includes('/menu/search')) {
    return SearchModes.MENU_SEARCH;
  }
  if (pathname.includes('mode=edit-subscription')) {
    return SearchModes.EDIT_SUBSCRIPTION;
  }
  if (pathname.includes('/menu')) {
    return SearchModes.Menu;
  }

  return SearchModes.NULL;
};

export function isNotEmpty(value: any) {
  // Check if the value is not null, undefined, or an empty string, array, or object
  if (typeof value === 'object') {
    return !_.isEmpty(value);
  }
  return value !== '' && !_.isNil(value);
}

const isNumericRangeValue = (val: any): val is Record<string, number> => {
  return (
    typeof val === 'object' &&
    ('_lt' in val || '_lte' in val || '_gt' in val || '_gte' in val)
  );
};

const isStringArray = (val: any): val is string[] => {
  return Array.isArray(val) && val.every((v) => typeof v === 'string');
};

const numericRangeFilter = (
  nutriValue: number,
  range: Record<string, number>,
): boolean => {
  if ('_lt' in range && !(nutriValue < range._lt!)) return false;
  if ('_lte' in range && !(nutriValue <= range._lte!)) return false;
  if ('_gt' in range && !(nutriValue > range._gt!)) return false;
  if ('_gte' in range && !(nutriValue >= range._gte!)) return false;
  return true;
};

export const getFilterFunction = (
  key: string,
  value: string | Record<string, any> | string[],
): ((product: ProductModelType) => boolean) | null => {
  switch (key) {
    case 'marketingTag':
      return (product) => {
        const productTags = product?.productTags;
        if (!productTags) return false;
        return productTags.some((tag: { id: string }) => tag?.id === value);
      };

    case 'proteinType':
      return (product) => {
        if (!product.nutritionalInfo || !isStringArray(value)) return false;
        return value.includes(product.nutritionalInfo.proteinType);
      };

    case 'carbs':
    case 'calories':
      return (product) => {
        if (!product.nutritionalInfo || !isNumericRangeValue(value))
          return false;
        const nutriValue = product.nutritionalInfo[key];
        return numericRangeFilter(nutriValue, value);
      };

    case 'dietary':
      return (product) => {
        const dietaryInfo = product.nutritionalInfo?.dietary;
        if (!dietaryInfo) return false;
        return dietaryInfo.includes(value as string);
      };

    case 'menuHierarchy':
      return (product) => {
        const { menuHierarchy } = product;
        if (!menuHierarchy) return false;
        return menuHierarchy.some(
          (category) => category?.key.toUpperCase() === value?.toUpperCase(),
        );
      };

    default:
      // If key is not recognized, return null
      return null;
  }
};

export const hideSearch = (pathName: string) => {
  return (
    pathName.includes('goalbased') ||
    (pathName.includes('action') && !pathName.includes('search')) ||
    pathName.includes('action=view')
  );
};

export const getScrollStyles = (scrollDir: ScrollDirection, mode: Mode) => {
  if (scrollDir === '') {
    return '';
  }
  if (scrollDir === 'down' && mode === Mode.EDIT_SUBSCRIPTION) {
    return 'top-[58px] ';
  }
  if (scrollDir === 'up' && mode === Mode.EDIT_SUBSCRIPTION) {
    return 'top-[110px]';
  }
  if (scrollDir === 'down') {
    return 'top-[21px]';
  }

  return 'top-[64px]';
};

const DEFAULT_WORKING_HOURS = [
  { days: [1, 2, 3, 4, 5], start: '09:00', end: '18:00' },
];

export const isInWorkingHours = (date?: Date | string) => {
  const WORKING_HOURS = process.env.NEXT_PUBLIC_WORKING_HOURS
    ? JSON.parse(process.env.NEXT_PUBLIC_WORKING_HOURS)
    : DEFAULT_WORKING_HOURS;
  const now = dayjs(date).tz(DEFAULT_TIMEZONE);
  const day = now.day(); // 0 for Sunday, 1 for Monday, etc.
  const time = now.format('HH:mm'); // Format the current time as 'HH:mm'

  // Find the working hours for the current day
  const workingHours = WORKING_HOURS.find((wh) => wh.days.includes(day));

  if (!workingHours) {
    // If there are no working hours defined for today, return false
    return false;
  }

  // Check if the current time is within the defined start and end time
  return time >= workingHours.start && time <= workingHours.end;
};

const getIconPath = (activeIcon: string): string | null => {
  const iconPaths = {
    PROCESSED: '/assets/icons/check_circle.svg',
    current: null,
    SKIPPED: '/assets/icons/skip_next.svg',
    PENDING: '/assets/icons/lock.svg',
  };
  return iconPaths[activeIcon as keyof typeof iconPaths] || '';
};

export const iconSubscriptionStatus = (activeIcon: string) => {
  const iconPath = getIconPath(activeIcon);
  if (!iconPath) return null;

  const iconSizes = {
    PROCESSED: { width: 16, height: 16 },
    SKIPPED: { width: 10, height: 10 },
    PENDING: { width: 12, height: 17 },
  };

  const { width, height } = iconSizes[activeIcon as keyof typeof iconSizes] || {
    width: 0,
    height: 0,
  };

  return {
    width,
    height,
    src: iconPath,
  };
};
