import { useState } from 'react';
import Autocomplete from 'react-google-autocomplete';

import type { Address } from '@/models/customer/address';

export interface GoogleAutoCompleteType {
  address?: Address;
  setValue: (name: string, value: string, options: any) => void;
  setAddress: (address: string) => void;
  googleAddressError: boolean;
}

export const GoogleAutoComplete: React.FC<GoogleAutoCompleteType> = ({
  address,
  setValue,
  setAddress,
  googleAddressError,
}) => {
  const [isStreetNumber, setStreetNumberError] = useState(false);
  let streetNumber = '';
  let streetName = '';
  let unitNumber = '';

  const setAddressFields = (place: any) => {
    if (!place) return;
    const components = place.address_components;
    if (!components) {
      setAddress(place.name);
      return;
    }
    for (let i = 0; i < components?.length; i += 1) {
      for (let j = 0; j < components[i].types.length; j += 1) {
        const type = components[i].types[j];
        switch (type) {
          case 'postal_code':
            setValue('postcode', components[i].long_name, {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: true,
            });
            break;
          case 'locality':
            setValue('suburb', components[i].long_name, {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: true,
            });
            break;
          case 'administrative_area_level_1':
            setValue('state', components[i].short_name, {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: true,
            });
            break;
          case 'street_number':
            streetNumber = components[i].short_name;
            break;
          case 'route':
            streetName = components[i].long_name;
            break;
          case 'subpremise':
            unitNumber = `${components[i].long_name}/`;
            break;
          default:
            break;
        }
      }
    }

    setStreetNumberError(!streetNumber);
    setAddress(`${unitNumber} ${streetNumber} ${streetName}`);
  };

  return (
    <>
      <Autocomplete
        apiKey={process.env.NEXT_PUBLIC_GOOGLE_KEY}
        onPlaceSelected={(place) => {
          setAddressFields(place);
        }}
        libraries={['places']}
        className="h-12 w-full rounded-md border border-grey-dark bg-clip-padding p-3 text-gray-700 outline-none"
        options={{
          types: ['address'],
          componentRestrictions: { country: 'au' },
        }}
        placeholder="Eg. 10 Main St"
        onChange={(e: any) => setAddress(e.target.value)}
        defaultValue={address?.street}
      />
      <span className={`absolute top-0 left-4 bg-white px-2 text-xs`}>
        Address
      </span>
      {googleAddressError && (
        <p className="text-sm text-error">Please fill in this field.</p>
      )}
      {isStreetNumber && (
        <p className="text-sm text-error">Please add the street number</p>
      )}
    </>
  );
};
