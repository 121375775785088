import type { NextPage } from 'next';
import React from 'react';

export type SelectTypes = {
  register: any;
  options: Array<string>;
  name: string;
  label?: string;
  error?: any;
  placeholder?: string;
  value?: string;
};

const BaseSelect: NextPage<SelectTypes> = ({
  register,
  options,
  name,
  label,
  error,
  placeholder,
  value,
}) => {
  const className =
    'block w-full appearance-none rounded bg-no-repeat p-3 transition ease-in-out focus:border-grey-dark focus:outline-none';
  const errorStyle = error ? 'text-error' : '';
  const border = error ? ' border-2 border-error' : ' border border-grey-dark';
  const background = error
    ? 'url("/assets/images/svg/error.svg") no-repeat 98% 50%'
    : '';
  const color = value ? ' text-gray-700' : ' text-gray-400';

  return (
    <>
      <select
        {...register(name)}
        style={{
          background,
        }}
        className={className + border + color}
      >
        <option value="" disabled defaultValue={value}>
          {placeholder}
        </option>
        {options.map((list: any) => (
          <option key={list} value={list}>
            {list}
          </option>
        ))}
      </select>
      {label && (
        <span
          className={`absolute left-4 top-0 bg-white px-2 text-xs ${errorStyle}`}
        >
          {label}
        </span>
      )}
      {error && <span className="text-sm text-error">{error?.message}</span>}
    </>
  );
};

export default BaseSelect;
