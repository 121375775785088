interface IRadioButton {
  isActive: boolean;
  isDisabled?: boolean;
}

export const RadioButton: React.FC<IRadioButton> = ({
  isActive,
  isDisabled = false,
}) => {
  if (isActive) {
    return (
      <div className="flex size-4 shrink-0 items-center justify-center rounded-full border-2 border-black bg-apricot-lite">
        <div className="size-2 rounded-full bg-black"></div>
      </div>
    );
  }
  if (isDisabled) {
    return (
      <div className="flex size-4 shrink-0 rounded-full border border-[#CCCCCC] bg-[#F5F6F9]" />
    );
  }
  return (
    <div className="flex size-4 shrink-0 rounded-full border border-grey-dark"></div>
  );
};
