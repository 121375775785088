// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum ProductRestrictedType {
  CART="CART",
ORDER="ORDER",
PRODUCT="PRODUCT"
}

/**
* ProductRestrictedType
*/
export const ProductRestrictedTypeEnumType = types.enumeration("ProductRestrictedType", [
        "CART",
  "ORDER",
  "PRODUCT",
      ])
