import { observer } from 'mobx-react-lite';
import Image from 'next/legacy/image';
import React, { useEffect, useState } from 'react';

import LoadingContainer from '@/components/common/LoadingContainer/LoadingContainer';
import TextInput from '@/components/common/TextInput/TextInput';
import { DELIVERY_INFO_SELECTOR } from '@/graphql/selectors';
import type { DeliveryInfoModelType } from '@/models/api';
import { useQuery } from '@/models/reactUtils';

type PostcodeEntryType = {
  register: any;
  handleClick: (props: any) => void;
  lookupClearBtn?: () => void;
  label: string;
  placeholder: string;
  isDeliveryPopup?: boolean;
};

const PostcodeEntry: React.FC<PostcodeEntryType> = observer(
  ({
    register,
    handleClick,
    lookupClearBtn,
    label,
    placeholder,
    isDeliveryPopup,
  }: PostcodeEntryType) => {
    const { setQuery, loading, error, data } = useQuery();
    const [lookup, setLookup] = useState('');
    const [showPostal, setShowPostal] = useState(false);
    const [isSplChars, setIsSplChars] = useState(false);

    useEffect(() => {
      if (lookup.length > 0) {
        if (!lookup.match(/^[a-zA-Z0-9 ]+$/)) {
          setIsSplChars(true);
        } else {
          setIsSplChars(false);
        }
      }
      if (lookup.length > 3 && !isSplChars) {
        setQuery((store: any) =>
          store.api.queryDeliveryInfo({ lookup }, DELIVERY_INFO_SELECTOR),
        );
        setShowPostal(true);
      } else {
        setShowPostal(false);
      }
    }, [lookup]);

    const postalData = ((data as any)?.deliveryInfo ||
      []) as DeliveryInfoModelType[];

    lookupClearBtn = () => {
      setShowPostal(false);
      setLookup('');
    };

    const handleSubmit = (event: any) => {
      event.preventDefault();
    };

    return (
      <div>
        {isDeliveryPopup && (
          <div className="flex flex-col items-center">
            <Image
              src="/assets/images/svg/delivery.svg"
              alt="delivery car"
              width={85}
              height={46}
            />
            <h3 className="pt-6 text-center font-interMedium text-sm leading-5 text-black md:text-lg md:leading-7 lg:max-w-[256px] ">
              Enter your postcode to find <br></br>the earliest delivery date
            </h3>
          </div>
        )}
        <div
          className={`${
            isDeliveryPopup && 'mx-auto flex w-[210px] flex-col justify-center'
          }`}
        >
          <div className={`relative`}>
            <div className={`relative`}>
              <form autoComplete="off" onSubmit={handleSubmit}>
                <TextInput
                  value={lookup}
                  placeholder={placeholder}
                  label={label}
                  name="postcode"
                  register={() => register('postcode')}
                  onChange={(event: any) => setLookup(event.target.value)}
                />
              </form>
              {lookup.length > 3 && (
                <button
                  className="absolute inset-y-0 right-3"
                  onClick={lookupClearBtn}
                >
                  <Image
                    src="/assets/icons/close_icon.svg"
                    alt="close"
                    width={18}
                    height={18}
                  />
                </button>
              )}
            </div>
            {showPostal ? (
              <ul
                className={`thin-scroll absolute z-10 max-h-48 w-full cursor-pointer bg-white px-3 text-left font-interMedium text-sm md:max-h-52`}
              >
                {postalData && postalData.length > 0
                  ? postalData.map((item, index: any) => (
                      <li
                        className={
                          'py-1 leading-4 last:mb-3 hover:bg-slate-100'
                        }
                        key={index}
                        onClick={() => {
                          handleClick(item);
                        }}
                      >
                        {item.name}, {item.state} {item.postcode}
                      </li>
                    ))
                  : null}
              </ul>
            ) : null}
          </div>
          <div className="flex flex-col justify-center text-center text-sm leading-snug">
            {lookup.length > 0 && lookup.length < 4 && !isSplChars && (
              <span>Please enter minimum of 4 characters</span>
            )}
            {loading && lookup.length > 3 && !isSplChars && (
              // <span>Loading...</span>
              <LoadingContainer />
            )}
            {error && lookup.length > 3 && !isSplChars && (
              <p>Sorry, we currently do not deliver to you just yet!</p>
            )}
            {isSplChars && lookup.length !== 0 && (
              <span>Special characters are not allowed</span>
            )}
          </div>
        </div>
      </div>
    );
  },
);

export default PostcodeEntry;
