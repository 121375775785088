import { Instance, types } from 'mobx-state-tree';

export const DeliveryModel = types
  .model('Delivery')
  .props({
    suburb: types.maybe(types.string),
    postcode: types.maybe(types.string),
  })
  .actions((self) => ({
    setDelivery(suburb: string, postcode: string) {
      self.suburb = suburb;
      self.postcode = postcode;
    },
    resetDelivery() {
      self.suburb = undefined;
      self.postcode = undefined;
    },
  }));

export interface Delivery extends Instance<typeof DeliveryModel> {}
