import randomise from 'randomatic';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import validator from 'validator';

import CheckBox from '@/components/checkout/Checkbox/CheckBox';
import Button from '@/components/common/Button/Button';
import BaseSelect from '@/components/common/Select/Select';
import TextInput from '@/components/common/TextInput/TextInput';
import { State } from '@/models/api/StateEnum';
import type { Customer } from '@/models/customer/customer';
import { isValidString } from '@/utils/helpers';

import { GoogleAutoComplete } from '../GoogleAutoComplete/GoogleAutoComplete';

type AddressType = 'edit' | 'add';
export interface IAddressForm {
  customer: Customer;
  address?: any; // no any
  saveAddress: (address: any) => void; // no any
  closeEditAddress: () => void;
  type: AddressType;
  disabled: boolean;
}

export const AddressFormCheckout: React.FC<IAddressForm> = ({
  customer,
  address,
  saveAddress,
  closeEditAddress,
  type,
  disabled = true,
}) => {
  const { firstName, lastName } = customer;
  const {
    register,
    getValues,
    setValue,
    formState: { errors, isDirty, isValid },
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues:
      address && Object.keys(address).length
        ? address
        : { firstName, lastName },
  });
  const [businessName, showBusinessName] = useState(
    !!(address && address.businessName),
  );
  const [googleAddress, setAddress] = useState(address ? address.street : '');
  const [googleAddressError, setGoogleAddressError] = useState(false);

  const onSave = async () => {
    // Trigger validation
    const isValidForm = await trigger();

    if (isValidForm) {
      const values = getValues();
      if (!googleAddress?.trim()) {
        setGoogleAddressError(true);
        return;
      }
      setGoogleAddressError(false);

      values.street = `${googleAddress} ${values.unit}`.trim();
      values.addressId = address
        ? address.addressId
        : `${randomise('Aa0', 16)}`;
      values.method = businessName ? 'work' : 'home';
      values.phone = values.phone.toString();
      delete values.unit;
      values.country = 'AU';
      values.businessName = businessName ? values.businessName : '';
      values.preferred =
        address && Object.keys(address).length > 0 ? address.preferred : false;

      saveAddress(values);
      if (address && Object.keys(address).length > 0) {
        closeEditAddress();
      }
    } else {
      // Scroll into the first error message
      const input = document.querySelector(`.text-error`);
      input?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }
  };

  const isValidPhone = (phone: string): string | boolean => {
    return (
      !phone ||
      validator.isMobilePhone(phone, 'en-AU') ||
      'Incorrect phone number'
    );
  };

  const onChange = (e: any) => {
    setValue(e.target.name, e.target.value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const renderText = () => {
    switch (type) {
      case 'edit':
        return 'Update';
      case 'add':
        return 'Save';
      default:
        return '';
    }
  };

  return (
    <form className="h-full">
      <div className="relative flex max-h-screen flex-col pt-3">
        <div className="px-4 py-2">
          <div className="flex w-full flex-col space-x-0 lg:flex-row lg:space-x-4">
            <div className="w-full lg:w-1/2">
              <TextInput
                label="First Name"
                name="firstName"
                placeholder="Eg. John"
                register={() =>
                  register('firstName', {
                    required: 'Please fill in this field.',
                    validate: isValidString,
                  })
                }
                error={errors?.firstName}
                onChange={onChange}
              />
            </div>
            <div className="w-full lg:w-1/2">
              <TextInput
                label="Last Name"
                name="lastName"
                placeholder="Eg. Smith"
                register={() =>
                  register('lastName', {
                    required: 'Please fill in this field.',
                    validate: isValidString,
                  })
                }
                error={errors?.lastName}
                onChange={onChange}
              />
            </div>
          </div>

          <CheckBox
            register={() => {}}
            label="Business Address"
            name="business"
            checked={businessName}
            onChange={() => showBusinessName(!businessName)}
          />
          {businessName && (
            <TextInput
              label="Business Name"
              name="businessName"
              placeholder="Company Name"
              register={() =>
                register('businessName', {
                  required: 'Please fill in this field.',
                })
              }
              error={errors?.businessName}
              onChange={onChange}
            />
          )}

          <div className="flex w-full flex-col space-x-0 lg:flex-row lg:space-x-4">
            {/* Google Address */}
            <div className="relative my-1 w-full py-2 lg:w-1/2">
              <GoogleAutoComplete
                address={address}
                setValue={setValue}
                setAddress={setAddress}
                googleAddressError={googleAddressError}
              />
            </div>
            <div className="w-full lg:w-1/2">
              <TextInput
                label="Address Line 2"
                name="unit"
                placeholder="Optional"
                register={() => register('unit')}
                onChange={onChange}
              />
            </div>
          </div>

          <div className="flex w-full flex-col space-x-0 lg:flex-row lg:space-x-4">
            <div className="w-full lg:w-1/2">
              <TextInput
                label="Suburb"
                name="suburb"
                placeholder="Eg. Sydney"
                register={() =>
                  register('suburb', {
                    required: 'Please fill in this field.',
                    validate: isValidString,
                  })
                }
                error={errors?.suburb}
                onChange={onChange}
              />
            </div>
            <div className="relative my-1 w-full py-2 lg:w-1/2">
              <BaseSelect
                label="State"
                placeholder="Eg. NSW"
                name="state"
                register={() =>
                  register('state', {
                    required: 'Please fill in this field.',
                  })
                }
                options={Object.values(State)}
                error={errors?.state}
                value={getValues()?.state}
              />
            </div>
          </div>

          <div className="flex w-full flex-col-reverse space-x-0 lg:flex-row lg:space-x-4">
            <div className="w-full lg:w-1/2">
              <TextInput
                label="Delivery Mobile Number"
                name="phone"
                placeholder="Eg. 0400 000 000"
                register={() =>
                  register('phone', {
                    required: 'Please fill in this field.',
                    validate: isValidPhone,
                  })
                }
                error={errors?.phone}
                onChange={onChange}
              />
            </div>
            <div className="w-full lg:w-1/2">
              <TextInput
                label="Postcode"
                name="postcode"
                placeholder="Eg. 2000"
                register={() =>
                  register('postcode', {
                    required: 'Please fill in this field.',
                    minLength: {
                      value: 4,
                      message: 'Please enter a valid postcode',
                    },
                    maxLength: {
                      value: 4,
                      message: 'Please enter a valid postcode',
                    },
                  })
                }
                error={errors?.postcode}
                onChange={onChange}
              />
            </div>
          </div>

          <TextInput
            label="Specific Instructions (Optional)"
            name="instructions"
            placeholder="Where should we leave the box?"
            register={() =>
              register('instructions', {
                maxLength: {
                  value: 250,
                  message: 'Please fill in less than 250 characters',
                },
              })
            }
            error={errors?.instructions}
          />
        </div>
        {/* Save AND Cancel */}
        <div className="sticky bottom-0 flex cursor-pointer justify-center space-x-4 border-t border-grey-liter bg-white p-4 font-interMedium">
          <button
            className="text-blueberry"
            type="button"
            onClick={() => closeEditAddress()}
          >
            Cancel
          </button>
          <Button
            id="save-address"
            type="button"
            onClick={onSave}
            theme={
              disabled || (!address && (!isDirty || !isValid))
                ? 'disabled'
                : 'primary'
            }
            disabled={disabled}
            loading={disabled}
          >
            {renderText()} address
          </Button>
        </div>
      </div>
    </form>
  );
};
