import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import Button from '@/components/common/Button/Button';
import { Modal } from '@/components/common/Modal/Modal';
import type { ProductRestrictedType } from '@/models/api';
import { useStore } from '@/models/root-store';

import PostcodeEntry from '../PostcodeEntry/PostcodeEntry';

interface DeliveryRestrictedSuburbCheckProps {
  open: boolean;
  onClose: () => void;
  productSkus: string[];
  name: string;
  type: ProductRestrictedType;
}

const customContentStyles = {
  width: 'fit-content',
  minWidth: 300,
  maxWidth: 348,
  maxHeight: '90%',
  overflow: 'initial',
};

// the component takes postcode input, return the delivery list
// the customer selects the delivery address to proceed product restriction check
// if the product is restricted, show the message and allow the customer enter another postcode
// if the product is not restricted, show the message and close the modal
const DeliveryRestrictedSuburbCheck: React.FC<DeliveryRestrictedSuburbCheckProps> =
  observer(({ open, onClose, productSkus, name, type }) => {
    const { register } = useForm();
    const [message, setMessage] = useState('');

    const { productStore } = useStore();
    const product = _.get(
      productStore.productsLookup,
      _.first(productSkus) || '',
    );

    const handleClick = async (props: any) => {
      const { postcode, name: suburb } = props;
      const postcodeString = postcode.toString();
      productStore.delivery.setDelivery(suburb, postcodeString);
      const result = await productStore.queryValidateRestrictedProducts(
        postcodeString,
        suburb,
        productSkus,
        type,
        true,
      );
      setMessage(result);
      if (!result) {
        onClose();
        toast.success('Great news! Delivery available to your location.');
      }
    };

    const handleClose = () => {
      setMessage('');
      onClose();
    };

    return (
      <Modal
        isOpen={open}
        onRequestClose={handleClose}
        headerTitle={''}
        customContentStyles={customContentStyles}
      >
        {message ? (
          <div className="flex flex-col items-center gap-6 px-7 py-8">
            <p
              className="mt-2 text-center"
              dangerouslySetInnerHTML={{ __html: message }}
            />
            <Button onClick={handleClose} className="px-6">
              Back to menu
            </Button>
            <Button
              className="px-6"
              theme="septenary"
              onClick={() => setMessage('')}
            >
              Enter new postcode
            </Button>
            <Link href={product?.cms?.faqLink || '/terms'} target="_blank">
              <span className="text-attention">Learn more</span>
            </Link>
          </div>
        ) : (
          <div className="flex flex-col items-center px-14 py-8">
            <div className="p-3">
              <Image
                src="/assets/images/svg/delivery_fresh.svg"
                alt="Delivery Fresh"
                width={61}
                height={40}
                className="rounded-l-lg"
              />
            </div>
            <div className="mb-11 mt-4">
              <p className=" text-center font-interBold">
                {`Enter your postcode to check if ${name} is available for
                delivery to your area`}
              </p>
              <p className="whitespace-pre-line">{message ?? ''}</p>
            </div>
            <div className={'min-h-32 min-w-60'}>
              <PostcodeEntry
                register={register}
                handleClick={handleClick}
                label="Postcode"
                placeholder="Eg. 2000"
              />
            </div>

            <Link href={product?.cms?.faqLink || '/terms'} target="_blank">
              <span className="text-attention">Learn more</span>
            </Link>
          </div>
        )}
      </Modal>
    );
  });

export default DeliveryRestrictedSuburbCheck;
