// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { MessageRtoModel, MessageRtoModelType } from "./MessageRtoModel"
import { MessageRtoModelSelector } from "./MessageRtoModel.base"
import { ProductModel, ProductModelType } from "./ProductModel"
import { ProductModelSelector } from "./ProductModel.base"
import { RootStoreType } from "./index"


/**
 * ProductRestrictedValidationRtoBase
 * auto generated base class for the model ProductRestrictedValidationRtoModel.
 *
 * ProductRestrictedValidation response
 */
export const ProductRestrictedValidationRtoModelBase = ModelBase
  .named('ProductRestrictedValidationRto')
  .props({
    __typename: types.optional(types.literal("ProductRestrictedValidationRTO"), "ProductRestrictedValidationRTO"),
    allRestrictedProducts: types.union(types.undefined, types.array(types.late((): any => ProductModel))),
    message: types.union(types.undefined, types.null, types.late((): any => MessageRtoModel)),
    restrictedProducts: types.union(types.undefined, types.array(types.late((): any => ProductModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class ProductRestrictedValidationRtoModelSelector extends QueryBuilder {
  allRestrictedProducts(builder: string | ProductModelSelector | ((selector: ProductModelSelector) => ProductModelSelector) | undefined) { return this.__child(`allRestrictedProducts`, ProductModelSelector, builder) }
  message(builder: string | MessageRtoModelSelector | ((selector: MessageRtoModelSelector) => MessageRtoModelSelector) | undefined) { return this.__child(`message`, MessageRtoModelSelector, builder) }
  restrictedProducts(builder: string | ProductModelSelector | ((selector: ProductModelSelector) => ProductModelSelector) | undefined) { return this.__child(`restrictedProducts`, ProductModelSelector, builder) }
}
export function selectFromProductRestrictedValidationRto() {
  return new ProductRestrictedValidationRtoModelSelector()
}

export const productRestrictedValidationRtoModelPrimitives = selectFromProductRestrictedValidationRto()
