export const LANDING_PAGE_SELECTOR = `
headersection {
  id
  buttonLink
  buttonText
  description
  title
  image {
    url(imgixParams: $imgixParams)
  }
}
productsection {
  __typename
  description
  title
  cards {
    primaryButtonText
    primaryButtonLink
    shortDescription
    product {
      displayName
      tileImage {
        url(imgixParams: $imgixParams)
        alt
      }
      sku
      grossPrice
      netPrice
    }
  }
}
benefitsection {
  __typename
  id
  title
  description
  list {
    title
    description
    icon {
      url(imgixParams: $imgixParams)
    }
  }
}
bundlesection {
  __typename
  id
  title
  description
  cards {
    primaryButtonText
    primaryButtonLink
    secondaryButtonText
    secondaryButtonLink
    shortDescription
    product {
      displayName
      tileImage {
        url(imgixParams: $imgixParams)
        alt
      }
      sku
      grossPrice
      netPrice
    }
  }
}
testimonialsection {
  __typename
    image {
      url(imgixParams: $imgixParams)
      title
      alt
    }
    title
    description
    author
}
faq {
  __typename
  id
  title
  description
}
`;

export const LANDING_PAGE_V2_SELECTOR = `
id
slug
seoTitle
seoDescription
sections {
  __typename
  ... on GeneralCampaignSectionRecord {
    id
    buttonLink
    buttonText
    description
    desktopImage {
      url(imgixParams: $imgixParams)
      alt
      title
    }
    mobileImage {
      url(imgixParams: $imgixParams)
      alt
      title
    }
    title
    tagText
    tagColor {
      hex
    }
    tagTextColor {
      hex
    }
  }
  ... on AccordionSectionRecord {
    id
    heading
    elements {
      id
      title
      description
    }
  }
  ... on GeneralDeliveryBannerSectionRecord {
    id
    buttonLink
    buttonText
    desktopBannerImage {
      url(imgixParams: $imgixParams)
      alt
      title
    }
    heading
    icon {
      url
      alt
      title
    }
    mobileBannerImage {
      url(imgixParams: $imgixParams)
      alt
      title
    }
    paragraphCopy
  }
  ... on GeneralHowItWorksSectionRecord {
    id
    buttonLink
    buttonText
    bulletList {
      id
      title
      description
      icon {
        url
        alt
        title
      }
    }
    desktopImage {
      url(imgixParams: $imgixParams)
      alt
      title
    }
    mobileImage {
      url(imgixParams: $imgixParams)
      alt
      title
    }
    title
  }
  ... on ImageSectionRecord {
    id
    imageSection {
      url(imgixParams: $imgixParams)
      alt
      title
    }
  }
  ... on LandingPageBenefitsSectionRecord {
    id
    description
    title
    list {
      id
      title
      description
      icon {
        url
        alt
        title
      }
    }
  }
  ... on LandingPageBundleSectionRecord {
    id
    description
    title
    cards {
      primaryButtonText
      primaryButtonLink
      secondaryButtonText
      secondaryButtonLink
      shortDescription
      product {
        displayName
        tileImage {
          url(imgixParams: $imgixParams)
          alt
          title
        }
        sku
        grossPrice
        netPrice
      }
    }
  }
  ... on LandingPageHeaderSectionRecord {
    id
    buttonLink
    buttonText
    buttonLinkTwo
    buttonTextTwo
    description
    title
    image {
      url(imgixParams: $imgixParams)
      alt
      title
    }
  }
  ... on LandingPageIngredientsSectionRecord {
    id
    title
    description
    list {
      id
      title
      description
      image {
        url(imgixParams: $imgixParams)
        alt
        title
      }
    }
  }
  ... on LandingPageProductSectionRecord {
    id
    title
    description
    cards {
      primaryButtonText
      primaryButtonLink
      shortDescription
      product {
        displayName
        tileImage {
          url(imgixParams: $imgixParams)
          alt
          title
        }
        sku
        grossPrice
        netPrice
      }
    }
  }
  ... on ProductCategorySectionRecord {
    id
    title
    description
    buttonLink
    buttonText
    buttonType
    cards {
      id
      heading
      description
      text
      link
      image {
        url(imgixParams: $imgixParams)
        alt
        title
      }
    }
  }
  ... on HomePageHeaderSectionRecord {
    primaryButtonLink
    primaryButtonText
    secondaryButtonLink
    secondaryButtonText
    title
    desktopImage {
      url(imgixParams: $imgixParams)
      alt
      title
    }
    description
    mobileImage {
      url(imgixParams: $imgixParams)
      alt
      title
    }
  }
  ... on GeneralPageLearnMoreRecord {
    buttonText
    buttonLink
    cards {
      description
      image {
        url(imgixParams: $imgixParams)
        alt
        title
      }
    }
  }
  ... on GeneralPlanSectionRecord {
    title
    plans {
      buttonLink
      buttonText
      image {
        url(imgixParams: $imgixParams)
        alt
        title
      }
      plan
    }
  }
  ... on GeneralReviewSectionRecord {
    title
    primaryButtonLink
    primaryButtonText
    secondaryButtonLink
    secondaryButtonText
    image {
      url(imgixParams: $imgixParams)
      alt
      title
    }
    reviews {
      review
      image {
        url(imgixParams: $imgixParams)
        title
        alt
      }
      author
    }
  }
  ... on GeneralStockistSectionRecord {
    title
    primaryButtonText
    primaryButtonLink
    secondaryButtonLink
    secondaryButtonText
    description
    desktopImage {
      url(imgixParams: $imgixParams)
      title
      alt
    }
    mobileImage {
      alt
      title
      url(imgixParams: $imgixParams)
    }
    stockistDesktopImage {
      alt
      title
      url(imgixParams: $imgixParams)
    }
    stockistMobileImage {
      alt
      title
      url(imgixParams: $imgixParams)
    }
  }
  ... on GeneralNutritionalTestimonialRecord {
    id
    title
    image {
      url(imgixParams: $imgixParams)
      title
      alt
    }
    author
    description
  }
  ... on TileSectionRecord {
    tile {
      id
      tileType
      title
      key
      active
      code
      products {
        sku
      }
    }
  }
  ... on CountdownSectionRecord {
    __typename
    backgroundImage {
      url
    }
    buttonLink
    buttonText
    buttonTheme
    countdownEndDate
    countdownText
    countdownSubtext
    active
    endedText
  }
}
`;

export const MENU_V2_SELECTOR = `
sections {
  ... on TileSectionRecord {
    tile {
      id
      tileType
      title
      key
      active
      code
      description {
        blocks
        value
        links
      }
      products {
        sku
      }
    }
  }
}
`;

export const DYNAMIC_LANDING_PAGE_SELECTOR = `id
campaign
landingPageVariations {
  id
  slug
  content
  landingPage {
    id
  }
}
`;
