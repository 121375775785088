export enum TrackerEvent {
  CartViewed = 'Cart Viewed', // A cart is viewed
  CheckoutStarted = 'Checkout Started', // Checkout is started
  CheckoutStepCompleted = 'Checkout Step Completed', // A checkout step has been completed
  CheckoutStepViewed = 'Checkout Step Viewed', // A checkout step has been viewed
  CouponApplied = 'Coupon Applied', // A valid coupon is applied to on an order
  CouponDenied = 'Coupon Denied', // A coupon is rejected
  CouponEntered = 'Coupon Entered', // A coupon is entered
  CouponRemoved = 'Coupon Removed', // A coupon is removed
  DeliverySkipped = 'Delivery Skipped', // A delivery is skipped
  DeliveryUnskipped = 'Delivery Unskipped', // A delivery is unskipped
  MealPlanAddedToCart = 'Meal Plan Added to Cart', // A meal plan is attached to an order (not cart), i.e. at this stage it is persisted
  MealPlanGenerated = 'Meal Plan Generated', // A new meal plan is generated (but not yet persistet)
  MealPlanRemovedFromCart = 'Meal Plan Removed from Cart', // A meal plan was removed from an order
  MealPlanUpdated = 'Meal Plan Updated', // A meal plan is updated (but that update is not yet persistet)
  OrderDelivered = 'Order Delivered', // An order is completed [server-side]
  OrderFailed = 'Order Failed', // An order failed
  OrderPlaced = 'Order Placed', // An order is completed
  OrderProcessed = 'Order Processed', // An order is completed [server-side]
  OrderRefunded = 'Order Refunded', // An order is refunded [server-side]
  OrderUpdated = 'Order Updated', // An order is updated [server-side] TODO: the delivery can be updated via client
  PaymentInfoEntered = 'Payment Info Entered', // Payment info is entered
  ProductAdded = 'Product Added', // A product is added to the cart
  ProductRemoved = 'Product Removed', // A product is removed from cart
  ProductClicked = 'Product Clicked', // A product is clicked
  ProductLiked = 'Product Liked', // A product is liked
  ProductUnliked = 'Product Unliked', // A product is unliked
  ProductListFiltered = 'Product List Filtered', // The user applies a filter or sort to a product list
  ProductListViewed = 'Product List Viewed', // Any time a product list is displayed (e.g. catalogue, homepage, landing page, meal plan).
  ProductViewed = 'Product Viewed', // A view of the product detail page (PDP)
  PromotionClicked = 'Promotion Clicked', // A promotion is clicked TODO: where to map in the app?
  PromotionViewed = 'Promotion Viewed', // Any time a promotion is viewed
  RecommendationViewed = 'Recommendation Viewed',
  ReferralCodeShared = 'Referral Code Shared', // Referral code copied or shared through other means
  SignedIn = 'Signed In', // A customer logs in TODO: web won't have this
  SignedOut = 'Signed Out', // A customer logs out
  UserSignedUp = 'User Signed Up', // New user created
  SubscriptionCancelled = 'Subscription Cancelled', // Subscription cancelled
  SubscriptionStarted = 'Subscription Started', // Subscription started; Only count net-new subscriptions. Trigger Subscription updated if a currently active subscription is overwritten
  SubscriptionUpdated = 'Subscription Updated', // Subscription updated
  Identify = 'Identify', // Identifier
  FormSubmitted = 'Form Submitted', // A form is submitted
  FormViewed = 'Form Viewed', // A form is viewed
  ProductsSearched = 'Products Searched', // A product search is performed
  LinkClicked = 'Link Clicked', // A link is clicked
  ModalViewed = 'Modal Viewed', // A modal is viewed
}

export enum CheckoutStep {
  DeliveryAddress = 'delivery_address',
  DeliveryDateTime = 'delivery_datetime',
  DeliveryFrequency = 'delivery_frequency',
  // DeliveryFrequencyRecurring = 'delivery_frequency_recurring', // TODO: remove comment after experiment
  PaymentMethod = 'payment_method',
}

export enum ExperimentStep {
  DeliveryFrequencyRecurring = 'delivery_frequency_recurring',
}

export enum CheckoutCompletionType {
  Prefill = 'prefill',
  Update = 'update',
  FirstFill = 'first-fill',
}

export enum TrackingSource {
  Web = 'web',
  App = 'app',
  Server = 'server',
}
