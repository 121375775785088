// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { ItemStatusEnumType } from "./ItemStatusEnum"
import { MenuHierarchyRecordModel, MenuHierarchyRecordModelType } from "./MenuHierarchyRecordModel"
import { MenuHierarchyRecordModelSelector } from "./MenuHierarchyRecordModel.base"
import { ProductRecordModel, ProductRecordModelType } from "./ProductRecordModel"
import { ProductRecordModelSelector } from "./ProductRecordModel.base"
import { ProductUpsellModelConditionsFieldModelSelector } from "./ProductUpsellModelConditionsFieldModelSelector"
import { SiteLocale } from "./SiteLocaleEnum"
import { TagModel, TagModelType } from "./TagModel"
import { TagModelSelector } from "./TagModel.base"
import { UpsellCustomerAttributeRuleRecordModel, UpsellCustomerAttributeRuleRecordModelType } from "./UpsellCustomerAttributeRuleRecordModel"
import { UpsellCustomerAttributeRuleRecordModelSelector } from "./UpsellCustomerAttributeRuleRecordModel.base"
import { UpsellMealPlanRuleRecordModel, UpsellMealPlanRuleRecordModelType } from "./UpsellMealPlanRuleRecordModel"
import { UpsellMealPlanRuleRecordModelSelector } from "./UpsellMealPlanRuleRecordModel.base"
import { UpsellMenuHierarchyRuleRecordModel, UpsellMenuHierarchyRuleRecordModelType } from "./UpsellMenuHierarchyRuleRecordModel"
import { UpsellMenuHierarchyRuleRecordModelSelector } from "./UpsellMenuHierarchyRuleRecordModel.base"
import { UpsellProductRuleRecordModel, UpsellProductRuleRecordModelType } from "./UpsellProductRuleRecordModel"
import { UpsellProductRuleRecordModelSelector } from "./UpsellProductRuleRecordModel.base"
import { RootStoreType } from "./index"


/**
 * ProductUpsellRecordBase
 * auto generated base class for the model ProductUpsellRecordModel.
 *
 * Record of type Product Upsell (product_upsell)
 */
export const ProductUpsellRecordModelBase = ModelBase
  .named('ProductUpsellRecord')
  .props({
    __typename: types.optional(types.literal("ProductUpsellRecord"), "ProductUpsellRecord"),
    _createdAt: types.union(types.undefined, types.frozen()),
    /** Editing URL */
    _editingUrl: types.union(types.undefined, types.null, types.string),
    _firstPublishedAt: types.union(types.undefined, types.null, types.frozen()),
    _isValid: types.union(types.undefined, types.frozen()),
    _modelApiKey: types.union(types.undefined, types.string),
    _publicationScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    _publishedAt: types.union(types.undefined, types.null, types.frozen()),
    /** Generates SEO and Social card meta tags to be used in your frontend */
    _seoMetaTags: types.union(types.undefined, types.array(types.late((): any => TagModel))),
    _status: types.union(types.undefined, ItemStatusEnumType),
    _unpublishingScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    _updatedAt: types.union(types.undefined, types.frozen()),
    conditions: types.union(types.undefined, types.array(types.union(types.late((): any => UpsellCustomerAttributeRuleRecordModel), types.late((): any => UpsellMealPlanRuleRecordModel), types.late((): any => UpsellMenuHierarchyRuleRecordModel), types.late((): any => UpsellProductRuleRecordModel)))),
    createdAt: types.union(types.undefined, types.frozen()),
    id: types.union(types.undefined, types.frozen()),
    limit: types.union(types.undefined, types.null, types.frozen()),
    menuHierarchies: types.union(types.undefined, types.array(types.late((): any => MenuHierarchyRecordModel))),
    name: types.union(types.undefined, types.null, types.string),
    position: types.union(types.undefined, types.null, types.frozen()),
    priority: types.union(types.undefined, types.null, types.frozen()),
    products: types.union(types.undefined, types.array(types.late((): any => ProductRecordModel))),
    updatedAt: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class ProductUpsellRecordModelSelector extends QueryBuilder {
  get _createdAt() { return this.__attr(`_createdAt`) }
  get _editingUrl() { return this.__attr(`_editingUrl`) }
  get _firstPublishedAt() { return this.__attr(`_firstPublishedAt`) }
  get _isValid() { return this.__attr(`_isValid`) }
  get _modelApiKey() { return this.__attr(`_modelApiKey`) }
  get _publicationScheduledAt() { return this.__attr(`_publicationScheduledAt`) }
  get _publishedAt() { return this.__attr(`_publishedAt`) }
  get _status() { return this.__attr(`_status`) }
  get _unpublishingScheduledAt() { return this.__attr(`_unpublishingScheduledAt`) }
  get _updatedAt() { return this.__attr(`_updatedAt`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get id() { return this.__attr(`id`) }
  get limit() { return this.__attr(`limit`) }
  get name() { return this.__attr(`name`) }
  get position() { return this.__attr(`position`) }
  get priority() { return this.__attr(`priority`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  _seoMetaTags(builder: string | TagModelSelector | ((selector: TagModelSelector) => TagModelSelector) | undefined, args?: { locale?: (SiteLocale | null) }) { return this.__child(`_seoMetaTags`+ (args ? '('+['locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), TagModelSelector, builder) }
  conditions(builder: string | ProductUpsellModelConditionsFieldModelSelector | ((selector: ProductUpsellModelConditionsFieldModelSelector) => ProductUpsellModelConditionsFieldModelSelector) | undefined) { return this.__child(`conditions`, ProductUpsellModelConditionsFieldModelSelector, builder) }
  menuHierarchies(builder: string | MenuHierarchyRecordModelSelector | ((selector: MenuHierarchyRecordModelSelector) => MenuHierarchyRecordModelSelector) | undefined) { return this.__child(`menuHierarchies`, MenuHierarchyRecordModelSelector, builder) }
  products(builder: string | ProductRecordModelSelector | ((selector: ProductRecordModelSelector) => ProductRecordModelSelector) | undefined) { return this.__child(`products`, ProductRecordModelSelector, builder) }
}
export function selectFromProductUpsellRecord() {
  return new ProductUpsellRecordModelSelector()
}

export const productUpsellRecordModelPrimitives = selectFromProductUpsellRecord()._createdAt._editingUrl._firstPublishedAt._isValid._modelApiKey._publicationScheduledAt._publishedAt._status._unpublishingScheduledAt._updatedAt.createdAt.limit.name.position.priority.updatedAt
