import { types, flow, cast, getSnapshot, applySnapshot } from 'mobx-state-tree';
import _ from 'lodash';
import {
  Frequency,
  LineItemModelType,
  OrderModel,
  SubscriptionEntityModel,
  SubscriptionEntityModelType,
  SubscriptionStatus,
} from '@/models/api';
import { toast } from 'react-toastify';
import { withRootStore } from '@/models/helpers/with-root-store';
import { SUBSCRIPTION_SELECTOR } from '@/graphql/selectors/subscription';
import { CartModel } from '../cart/cart';
import { OrderPlan } from '../cart/order-plan';
import { parseDraftOrderFromSubscription } from '../helpers/sub-helper';
import type { Address } from '@/models/customer/address';
import { DEFAULT_ERROR_MESSAGE, GQL_QUERY_OPTIONS, NOT_FOUND_STATUS } from '@/utils/constants';
import {
  objectParser,
} from '@/utils/track/format-helpers';
import { ORDER_SELECTOR } from '@/graphql/selectors';
import { trackDeliverySkipped, trackDeliveryUnskipped, trackSubscriptionCancelled, trackSubscriptionUpdated } from '@/utils/track/tracker.helper';
import menuBuilderData from '@/mock-data/menu-builder.json';

export const SubscriptionStoreModel = types
  .model('SubscriptionStore')
  .props({
    isLoading: types.optional(types.boolean, false),
    subscription: types.union(
      types.undefined,
      types.null,
      SubscriptionEntityModel
    ),
    draftOrder: types.maybe(OrderModel),
    subCart: types.union(types.undefined, types.null, CartModel),
    showCancelResponseModal: types.optional(types.boolean, false),
  })
  .extend(withRootStore)
  .views((self) => ({
    get isSubscribed() {
      return (
        !!self.subscription &&
        self.subscription.status === SubscriptionStatus.ACTIVE
      );
    },
    isSubscriptionEditable(productsLookup: any) {
      const hasDiscontinuedItem = _.some(
        self.draftOrder?.orderPlans,
        (plan) => {
          _.some(plan.lineItems, (item: LineItemModelType) => {
            const itemObj = getSnapshot(item);
            return !itemObj.sku || !productsLookup[itemObj.sku];
          });
        }
      );
      return !hasDiscontinuedItem;
    },
  }))
  .actions((self) => {
    let initialState = {};

    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      reset: () => {
        applySnapshot(self, initialState);
      },
      setSubscription(subscription: SubscriptionEntityModelType) {
        self.subscription = cast(subscription);
        self.draftOrder = cast(parseDraftOrderFromSubscription(subscription));
      },
      setShowCancelResponseModal(show: boolean) {
        self.showCancelResponseModal = show;
      },
    };
  })
  .actions((self) => ({
    getSubscription: flow(function* (ignoreNotFound: boolean = true) {
      try {
        const { subscription } = yield self.rootStore.api.querySubscription(
          {},
          SUBSCRIPTION_SELECTOR,
          GQL_QUERY_OPTIONS
        ).promise;
        self.setSubscription(subscription);
        return true;
      } catch (error: any) {
        const status = _.get(
          error,
          'response.errors[0].extensions.exception.status'
        );
        if (ignoreNotFound && status === NOT_FOUND_STATUS) {
          return false;
        }
        const { errors } = error.response;
        const errorMeassage = errors[0].message || DEFAULT_ERROR_MESSAGE;
        toast.error(errorMeassage);
        return false;
      }
    }),
    cancelSubscription: flow(function* (reason: string) {
      try {
        const sub = objectParser(self.subscription);
        const { cancelSubscription: subscription } =
          yield self.rootStore.api.mutateCancelSubscription({
            input: {
              reason: reason,
            },
          }).promise;
        self.setSubscription(subscription);
        self.setShowCancelResponseModal(true);
        trackSubscriptionCancelled(sub, self.rootStore.productStore.productsFullList, reason);
        return true;
      } catch (error: any) {
        const { errors } = error.response;
        const errorMeassage = errors[0].message || DEFAULT_ERROR_MESSAGE;
        toast.error(errorMeassage);
        return false;
      }
    }),
    updatePlans: flow(function* (plans: OrderPlan[]) {
      try {
        const { updateSubscriptionPlans: subscription } =
          yield self.rootStore.api.mutateUpdateSubscriptionPlans(
            { input: { plans } },
            SUBSCRIPTION_SELECTOR
          ).promise;
        self.setSubscription(subscription);
        try {
          trackSubscriptionUpdated(self.subscription, self.rootStore.productStore.productsFullList);
        } catch (error) {
          // ignore error
        }
        return true;
      } catch (error: any) {
        const { errors } = error.response;
        const errorMeassage = errors[0].message || DEFAULT_ERROR_MESSAGE;
        toast.error(errorMeassage);
        return false;
      }
    }),
    updateFrequency: flow(function* (frequency: Frequency) {
      try {
        self.isLoading = true;
        const { updateSubscriptionFrequency: subscription } =
          yield self.rootStore.api.mutateUpdateSubscriptionFrequency(
            { input: { frequency } },
            SUBSCRIPTION_SELECTOR
          ).promise;
        self.setSubscription(subscription);
        try {
          trackSubscriptionUpdated(self.subscription, self.rootStore.productStore.productsFullList);
        } catch (error) {
          // ignore error
        }
        self.isLoading = false;
        return true;
      } catch (error: any) {
        self.isLoading = false;
        const { errors } = error.response;
        const errorMeassage = errors[0].message || DEFAULT_ERROR_MESSAGE;
        toast.error(errorMeassage);
        return false;
      }
    }),
    skipDelivery: flow(function* (deliveryDate: string) {
      try {
        const { updateSubscriptionFutures: subscription } =
          yield self.rootStore.api.mutateUpdateSubscriptionFutures(
            { input: { deliveryDate } },
            SUBSCRIPTION_SELECTOR
          ).promise;
        self.setSubscription(subscription);
        try {
          const skippedDay = subscription.futures.find(
            (i: any) => i.deliveryDate === deliveryDate
          );
          if (skippedDay.status === 'SKIPPED') {
            trackDeliverySkipped(objectParser(self.subscription), self.rootStore.productStore.productsFullList);
          } else {
            trackDeliveryUnskipped(objectParser(self.subscription), self.rootStore.productStore.productsFullList);
          }
        } catch (error) {
          // ignore error
        }
        return true;
      } catch (error: any) {
        const { errors } = error.response;
        const errorMeassage = errors[0].message || DEFAULT_ERROR_MESSAGE;
        toast.error(errorMeassage);
        return false;
      }
    }),
    updateSubscriptionDeliveryAddress: flow(function* (address: Address) {
      try {
        const { updateSubscriptionDelivery: subscription } =
          yield self.rootStore.api.mutateUpdateSubscriptionDelivery(
            {
              input: {
                address: address,
              },
            },
            SUBSCRIPTION_SELECTOR
          ).promise;
        self.setSubscription(subscription);
        try {
          trackSubscriptionUpdated(self.subscription, self.rootStore.productStore.productsFullList);
        } catch (error) {
          // ignore error
        }
        return true;
      } catch (error: any) {
        const { errors } = error.response;
        const errorMeassage = errors[0].message || DEFAULT_ERROR_MESSAGE;
        toast.error(errorMeassage);
        return false;
      }
    }),
  }))
  .actions((self) => ({
    updateSubscriptionDelivery: flow(function* (data) {
      try {
        const { updateSubscriptionDeliveryDate: subscription } =
          yield self.rootStore.api.mutateUpdateSubscriptionDeliveryDate(
            { input: data },
            SUBSCRIPTION_SELECTOR
          ).promise;
        self.setSubscription(subscription);
        try {
          trackSubscriptionUpdated(self.subscription, self.rootStore.productStore.productsFullList);
        } catch (error) {
          // ignore error
        }
        return true;
      } catch (error: any) {
        const { errors } = error.response;
        const errorMeassage = errors[0].message || DEFAULT_ERROR_MESSAGE;
        toast.error(errorMeassage);
        return false;
      }
    }),
    shipNow: flow(function* (deliveryDate: string, timeSlot: string) {
      try {
        const variables = { input: { deliveryDate, timeSlot } };
        const { processSubscriptionShipNow: result } =
          yield self.rootStore.api.mutateProcessSubscriptionShipNow(
            variables,
            ORDER_SELECTOR
          ).promise;
        return result;
      } catch (error: any) {
        const { errors } = error.response;
        const errorMeassage = errors[0].message || DEFAULT_ERROR_MESSAGE;
        toast.error(errorMeassage);
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    initSubCart() {
      if (!self.subscription) return;
      if (!self.isSubscriptionEditable) {
        throw new Error('Subscription is not editable, please contact us');
      }
      const plans = _.map(self.subscription.plans, (plan) => ({
        ...plan,
        attributes: _.map(plan.attributes, 'payload'),
        lineItems: _.map(plan.lineItems, (lineItem) => ({
          sku: lineItem.sku,
          qty: lineItem.qty,
          attributes: _.map(lineItem.attributes, 'payload'),
        })),
      }));
      self.subCart = CartModel.create({
        cartId: self.subscription._id,
        plans: plans,
      });
      // add menu builder plan if it does not exist
      if (!self.subCart.menuBuilderPlan) {
        self.subCart.initOrderPlan(menuBuilderData);
      }
    },
  }));
