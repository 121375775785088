import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { Modal } from '@/components/common/Modal/Modal';
import { ProductRestrictedType } from '@/models/api';
import type { Address } from '@/models/customer/address';
import { useStore } from '@/models/root-store';

import AddressContainer from './AddressContainer';

interface DeliveryRestrictedAddressCheckProps {
  open: boolean;
  onClose: () => void;
  deliveryAddress: string;
  name: string;
}

const customContentStyles = {
  width: '90vw',
  // minWidth: '90vw',
  maxWidth: 632,
  maxHeight: '90%',
  // overflow: 'initial',
};

// the component takes postcode input, return the delivery list
// the customer selects the delivery address to proceed product restriction check
// if the product is restricted, show the message and allow the customer enter another postcode
// if the product is not restricted, show the message and close the modal
const DeliveryRestrictedAddressCheck: React.FC<DeliveryRestrictedAddressCheckProps> =
  observer(({ open, onClose, name, deliveryAddress }) => {
    const {
      customerStore: {
        customer,
        createAddress,
        updateAddress,
        isEditingSubscription,
      },
      cartStore: { cart, draftCart },
      productStore: { queryValidateRestrictedProducts },
      generalStore: { restrictedProductsCheck },
    } = useStore();
    const { addresses } = customer;

    const [changingAddress, setChangingAddress] = useState(false);
    const handleClose = () => {
      setChangingAddress(false);
      onClose();
    };

    const handleChangeAddress = async (address: Address) => {
      try {
        if (isEditingSubscription) {
          // update the default address
          await updateAddress({ ...address, preferred: true });
          handleClose();
        } else {
          // update the delivery address
          cart.setDeliveryAddress(address);
          draftCart?.setDeliveryAddress(address);
          const result = await queryValidateRestrictedProducts(
            address.postcode,
            address.suburb,
            restrictedProductsCheck,
            ProductRestrictedType.PRODUCT,
            true,
          );
          if (!result) {
            handleClose();
            toast.success('Great news! Delivery available to your location.');
          } else {
            setChangingAddress(false);
          }
        }
      } catch (error) {
        setChangingAddress(false);
      }
    };

    const handleSaveAddress = async (address: Address, isNew: boolean) => {
      try {
        if (isNew) {
          await createAddress(address);
        } else {
          await updateAddress(address);
        }
        setChangingAddress(false);
        await handleChangeAddress(address);
      } catch (error: any) {
        const { message } = error.response.errors[0];
        toast.error(message);
      }
    };

    return (
      <Modal
        isOpen={open}
        onRequestClose={handleClose}
        headerTitle={''}
        customContentStyles={customContentStyles}
      >
        {changingAddress ? (
          <AddressContainer
            addresses={addresses}
            customer={customer}
            onChangeAddress={handleChangeAddress}
            onSaveAddress={handleSaveAddress}
          />
        ) : (
          <div className="flex flex-col items-center gap-6 px-7 py-8">
            <p className="mx-4 mt-4 whitespace-pre-line text-center">
              {`We're sorry! It looks like we’re unable to deliver ${name} to\n`}
              <span className="font-interSemibold">{deliveryAddress}.</span>
            </p>
            <div
              className="cursor-pointer font-interMedium text-attention"
              onClick={() => setChangingAddress(true)}
            >
              Change Address
            </div>
          </div>
        )}
      </Modal>
    );
  });

export default DeliveryRestrictedAddressCheck;
